document.addEventListener('DOMContentLoaded', function() {
  const scrollContents = document.querySelectorAll('.scroll-content-item .simplebar-content');


  scrollContents.forEach(scrollContent => {
    const height = scrollContent.scrollHeight;

    if (height) {

      const maskElement = scrollContent.closest('.simplebar-mask');
      if (maskElement) {

        const placeholderElement = maskElement.nextElementSibling;
        if (placeholderElement && placeholderElement.classList.contains('simplebar-placeholder')) {
          placeholderElement.style.height = height + 'px';
        }
      }
    }
  });
});
